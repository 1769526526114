@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Source+Code+Pro:wght@400;500&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-weight: 400;
    border-width: 0;
    border-style: solid;
    /* border: 1px solid red; */
}

body {
    font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #19232d;
    padding: 0 24px;
    max-width: 42rem;
    margin: 0 auto;
    margin-bottom: 160px;
}

code {
    font-family: "Source Code Pro", monospace !important;
    padding-left: 20px !important;
    font-weight: 500;
}

code > span {
    font-weight: 500;
}

p {
    margin-bottom: 1.5rem;
    line-height: 1.5rem;
}

h1 {
    font-size: 38px;
    font-weight: 500;
}

h2 {
    font-size: 27px;
}

.color-span {
    color: #00e100;
    filter: brightness(0.8);
    font-weight: 500;
}

.code-h {
    background-color: rgb(233, 233, 233);
    padding: 2px 6px;
    border-radius: 5px;
}

a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
}

@tailwind base;
@tailwind components;
@tailwind utilities;
